.navbar-lg {
  display: none;
  z-index: 1;
}

.navbar {
  // padding: 1em 0;
  position: fixed;
  width: 100%;
  height: 10vh;
  color: $clr-white;
  z-index: 1;

  .navbar-sm {
    padding: 1.25em 0;
    @include flex;
    justify-content: space-between;
    background-color: $clr-black;

    .container {
      @include flex;
      justify-content: space-between;

      .social-links span {
        margin-left: 0.5em;
      }
    }
  }
  .menu {
    // display: none;
    // @include flex;
    justify-content: center;
    gap: 2em;
    background-color: $card-bg;
    height: 100%;
    padding: 0.65em 0;
    width: 80%;
    margin: 0 auto;

    .nav-link li {
      color: $clr-white;
      opacity: 0.85;
    }
    .nav-link:hover,
    .nav-link:focus {
      opacity: 1;
      transition: 0.5s;
    }
  }
}

.hide {
  display: none;
}
.show {
  @include flex;
}

@media screen and (min-width: 900px) {
  .navbar {
    display: none;
  }
  .navbar-lg {
    position: fixed;
    padding: 1em 0;
    background-color: $clr-black;
    width: 5vw;
    min-height: 100vh;
    color: $clr-white;
    @include flex;
    flex-direction: column;
    justify-content: space-between;

    .social-links,
    .links {
      @include flex;
      flex-direction: column;
      gap: 1em;
    }
    .links {
      .nav-link {
        color: $clr-white;
        opacity: 0.65;
      }
    }
    .social-links {
      span {
        cursor: pointer;
        opacity: 0.75;
      }
    }

    .nav-link:hover,
    .nav-link:focus {
      opacity: 1;
    }
  }
}
