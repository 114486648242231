*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body{
  background: linear-gradient(
    90deg,
    rgba(58, 28, 78, 1) 0%,
    rgba(0, 0, 0, 1) 0%,
    rgba(69, 63, 63, 1) 100%
  );
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}



ul {
  list-style-type: none;
}

