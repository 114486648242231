@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Open+Sans:wght@400;500;700&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: linear-gradient(90deg, rgb(58, 28, 78) 0%, rgb(0, 0, 0) 0%, rgb(69, 63, 63) 100%);
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
}

body {
  font-family: "Montserrat", sans-serif;
}

.logo {
  font-weight: 700;
  letter-spacing: 0.075rem;
}

.heading,
.sub-heading,
.title,
.para,
.footer-para {
  text-align: center;
}

.heading,
.sub-heading {
  font-size: 2rem;
  text-transform: capitalize;
  color: #fff;
  margin: 0.65em 0 0.1em 0;
  letter-spacing: 0.065rem;
}

.sub-heading {
  font-size: 1.5rem;
  color: #181818;
}

.title {
  font-size: 1.25rem;
  margin: 0.5em 0;
}

.para,
.footer-para {
  padding-bottom: 0.25em;
  font-size: 0.85rem;
  opacity: 0.85;
  line-height: 1.25rem;
}

li {
  font-size: 0.95rem;
}

.footer .nav-link li {
  font-size: 0.75rem;
}

span {
  font-size: 1.15rem;
}

.caption {
  margin-top: 0.5em;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
}

@media screen and (min-width: 900px) {
  .para {
    text-align: left;
  }
  .heading {
    font-size: 2.65rem;
  }
}
.underline {
  width: 80px;
  border: 1px solid #fff;
  color: #fff;
  margin: 0 auto 1em auto;
}

.flex {
  display: flex;
  gap: 0.35em;
  align-items: center;
  justify-content: center;
}

.container {
  width: 90%;
  margin: 0 auto;
  max-width: 800px;
}

.mt {
  margin-top: 3em;
}

@media screen and (min-width: 900px) {
  .mt {
    margin-top: 0;
  }
}
.intro .heading {
  display: inline-block;
  font-size: 36px;
  display: flex;
  gap: 0.125em;
}
.intro .heading .letter {
  font-size: 2rem;
  display: inline-block;
  animation-play-state: paused;
  cursor: default;
}
.intro .heading .letter:hover {
  transform: translateY(-8px);
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-center 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn-container {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1em;
}
.btn-container .btn {
  padding: 0.65em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  min-width: 150px;
  cursor: pointer;
  border: none;
  background-color: #fff;
  transition: 0.65s ease-out;
  border-radius: 0.5em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.125rem;
}
.btn-container .btn:hover,
.btn-container .btn:focus {
  background: transparent;
  box-shadow: inset 0px 0px 0px 1px #fff;
  color: #fff;
}

@media screen and (min-width: 900px) {
  .btn-container {
    flex-direction: row;
    justify-content: center;
  }
  .btn-container .btn {
    min-width: 120px;
  }
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0.5em 0;
  position: absolute;
  z-index: 1;
  left: 130%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #181818 transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.navbar-lg {
  display: none;
  z-index: 1;
}

.navbar {
  position: fixed;
  width: 100%;
  height: 10vh;
  color: #fff;
  z-index: 1;
}
.navbar .navbar-sm {
  padding: 1.25em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #181818;
}
.navbar .navbar-sm .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar .navbar-sm .container .social-links span {
  margin-left: 0.5em;
}
.navbar .menu {
  justify-content: center;
  gap: 2em;
  background-color: rgba(0, 0, 0, 0.65);
  height: 100%;
  padding: 0.65em 0;
  width: 80%;
  margin: 0 auto;
}
.navbar .menu .nav-link li {
  color: #fff;
  opacity: 0.85;
}
.navbar .menu .nav-link:hover,
.navbar .menu .nav-link:focus {
  opacity: 1;
  transition: 0.5s;
}

.hide {
  display: none;
}

.show {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 900px) {
  .navbar {
    display: none;
  }
  .navbar-lg {
    position: fixed;
    padding: 1em 0;
    background-color: #181818;
    width: 5vw;
    min-height: 100vh;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .navbar-lg .social-links,
  .navbar-lg .links {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1em;
  }
  .navbar-lg .links .nav-link {
    color: #fff;
    opacity: 0.65;
  }
  .navbar-lg .social-links span {
    cursor: pointer;
    opacity: 0.75;
  }
  .navbar-lg .nav-link:hover,
  .navbar-lg .nav-link:focus {
    opacity: 1;
  }
}
.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(90deg, rgb(58, 28, 78) 0%, rgb(0, 0, 0) 0%, rgb(69, 63, 63) 100%);
}
.hero-section .intro {
  animation-delay: 0.4s;
  color: #fff;
  text-align: center;
  margin-top: 10vh;
}

@media screen and (min-width: 900px) {
  .hero-section {
    min-height: 100vh;
  }
  .hero-section .intro {
    margin-top: 0;
  }
}
.hero-section .container-about {
  animation-delay: 0.5s;
  max-width: 400px;
}
.hero-section .container-about .about-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2em;
}
.hero-section .container-about .about-container .img-container {
  width: 60%;
}
.hero-section .container-about .about-container .bio {
  padding: 0 1em;
}
.hero-section .container-about .about-container .bio .para {
  color: rgba(255, 255, 255, 0.85);
}

@media screen and (min-width: 900px) {
  .hero-section .container-about {
    max-width: 800px;
  }
  .hero-section .container-about .about-container {
    flex-direction: row;
  }
  .hero-section .container-about .about-container .img-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hero-section .container-about .about-container .img-container img {
    width: 80%;
  }
  .hero-section .container-about .about-container .bio {
    width: 50%;
  }
}
.hero-section .container .skills-container {
  display: grid;
  gap: 0.65em;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fit, 8em);
  justify-content: center;
}
.hero-section .container .skills-container .skill {
  padding: 0.75em 1em;
  background-color: rgba(0, 0, 0, 0.65);
  transition: 0.8s ease;
}
.hero-section .container .skills-container .skill img {
  width: 100%;
  height: 100px;
  display: block;
  object-fit: contain;
}
.hero-section .container .skills-container .skill:hover,
.hero-section .container .skills-container .skill:focus {
  transform: scale(1.15) !important;
}

.skill:nth-child(1) {
  animation-delay: 0.25s;
}

.skill:nth-child(2) {
  animation-delay: 0.5s;
}

.skill:nth-child(3) {
  animation-delay: 0.75s;
}

.skill:nth-child(4) {
  animation-delay: 1s;
}

.skill:nth-child(5) {
  animation-delay: 1.25s;
}

.skill:nth-child(6) {
  animation-delay: 1.5s;
}

.skill:nth-child(7) {
  animation-delay: 1.75s;
}

.skill:nth-child(8) {
  animation-delay: 2s;
}

@media screen and (min-width: 900px) {
  .hero-section .container .skills-heading {
    margin-top: 0;
  }
}
.contact-page {
  animation-delay: 0.5s;
  flex-direction: column;
  gap: 2em;
  letter-spacing: 0.06rem;
}
.contact-page .contact-info {
  min-width: 250px;
  max-width: 300px;
}
.contact-page .contact-info .title {
  color: #fff;
  font-size: 0.8rem;
  margin-bottom: 0;
  text-align: left;
  text-transform: uppercase;
}
.contact-page .contact-info .para {
  text-align: left;
  color: rgba(255, 255, 255, 0.65);
}
.contact-page .form-container {
  animation-delay: 0.5s;
  min-width: 250px;
  max-width: 400px;
}
.contact-page .form-container form {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1em;
}
.contact-page .form-container form .input-container {
  width: 100%;
}
.contact-page .form-container form .input-container input, .contact-page .form-container form .input-container textarea {
  letter-spacing: inherit;
  width: 100%;
  padding: 0.5em 1em;
  border: none;
  border-radius: 5px;
}
.contact-page .form-container form .input-container textarea {
  resize: none;
  height: 60px;
}
.contact-page .form-container form .input-container .error {
  color: rgba(185, 70, 70, 0.85);
  font-size: 0.8rem;
}
.contact-page .form-container form .btn-container {
  padding: 0.5em;
}

@media screen and (min-width: 700px) {
  .contact-page {
    flex-direction: row;
    gap: 4em;
  }
  .contact-page .form-container .heading {
    margin-top: 0;
  }
}
@media screen and (min-width: 1000px) {
  .contact-page {
    gap: 4em;
  }
  .contact-info {
    width: 40%;
  }
  .form-container {
    width: 60%;
  }
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 16em);
  grid-auto-rows: auto;
  justify-content: center;
  gap: 1em;
}
.grid-container .card {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
  min-height: 200px;
  overflow: hidden;
  letter-spacing: 0.05rem;
}
.grid-container .card .overlay {
  position: absolute;
  height: 60%;
  width: 100%;
  bottom: -120px;
  left: 0;
  transition: 0.5s ease;
  background-color: rgba(24, 24, 24, 0.75);
  z-index: 2;
}
.grid-container .overlay-content {
  color: #fff;
  padding: 0 1em 0.5em 1em;
}
.grid-container .overlay-content .tags-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.25em;
  margin-bottom: 0.5em;
}
.grid-container .overlay-content .tags-container .tag {
  font-size: 0.8rem;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.85);
  color: #181818;
  padding: 0.25em 0.5em;
  border-radius: 10px;
  cursor: pointer;
  text-transform: capitalize;
}
.grid-container .card:hover .overlay {
  bottom: 0px;
}

.card:nth-child(1) {
  animation-delay: 0.25s;
}

.card:nth-child(2) {
  animation-delay: 0.5s;
}

.card:nth-child(3) {
  animation-delay: 0.75s;
}

.card:nth-child(4) {
  animation-delay: 1s;
}

.card:nth-child(5) {
  animation-delay: 1.25s;
}

.card:nth-child(6) {
  animation-delay: 1.5s;
}

.card:nth-child(7) {
  animation-delay: 1.75s;
}

.card:nth-child(8) {
  animation-delay: 2s;
}

@media (hover: none) {
  .grid-container .card .overlay {
    bottom: 0;
  }
}

