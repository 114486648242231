
body{
    font-family: $font-family;
}

.logo{
    font-weight: 700;
    letter-spacing: 0.075rem;
}

.heading,
.sub-heading,
.title,
.para,
.footer-para{
    text-align: center;
}

.heading,
.sub-heading{
    font-size: $base-font-size * 2;
    text-transform: capitalize;
    color: $clr-white;
    margin: .65em 0 0.1em 0;
    letter-spacing: .065rem;
}
.sub-heading{
    font-size: $base-font-size * 1.5;
    color: $clr-black;
}

.title{
    font-size: $base-font-size * 1.25;
    margin: .5em 0;
}
.para,
.footer-para{
    padding-bottom: .25em ;
    font-size: $base-font-size * .85;
    opacity: 0.85;
    line-height: 1.25rem;
}

li{
    font-size: $base-font-size * .95;
}

.footer{
    .nav-link li{
        font-size: $base-font-size * 0.75;
    }
}

span{
    font-size: $base-font-size * 1.15;
}

.caption{
    margin-top: .5em;
    font-weight: 500;
    font-size: $base-font-size * 1;
    text-align: center;
    color: $clr-white;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
}

@media screen and (min-width: 900px) {
    .para{
        text-align: left;
    }
    .heading{
        font-size: $base-font-size * 2.65;
    }
}
