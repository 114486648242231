@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Open+Sans:wght@400;500;700&display=swap');


// $clr-primary: #;
// $clr-secondary: #;

$clr-white: #fff;
$clr-black: #181818;

$card-bg: rgba(0, 0, 0, 0.65);
$bg: linear-gradient(135deg, #1d1e21 0%, #3e4246 100%);

$clr-error: rgba(rgb(185, 70, 70), 0.85);


$font-family: 'Montserrat', sans-serif ;
$base-font-size: 1rem;
// $font-family: 'Open Sans', sans-serif;

// font-family: 'Montserrat', sans-serif; // Header and Nav
// font-family: 'Open Sans', sans-serif; // Title and body