// letter jump animation when hovered
.intro{
  .heading {
    display: inline-block;
    font-size: 36px;
    display: flex;
    gap: .125em;
  }
  
  .heading .letter {
    font-size: $base-font-size * 2;
    display: inline-block; 
    animation-play-state: paused; 
    cursor: default;
  }

  .heading .letter:hover {
    transform: translateY(-8px);
  }
}





// Scale Up Center

.scale-up-center {
  -webkit-animation: scale-up-center 0.5s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}




// Loading


.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  // margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


