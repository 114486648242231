
.tooltip {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted $clr-black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: rgba($color: #000000, $alpha: 0.85);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: .5em 0;
    position: absolute;
    z-index: 1;
    // top: -5px;
    left: 130%;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent $clr-black transparent transparent;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }