.hero-section {
  .container {
    .skills-heading{
      // margin-top: 3em;
    }
    .skills-container {
      display: grid;
      gap: 0.65em;
      grid-auto-rows: auto;
      grid-template-columns: repeat(auto-fit, 8em);
      justify-content: center;
      .skill {
        padding: 0.75em 1em;
        background-color: $card-bg;
        transition: 0.8s ease;
        
        img {
          width: 100%;
          height: 100px;
          display: block;
          object-fit: contain;
        }
      }
      .skill:hover,
      .skill:focus {
        // cursor: pointer;
        transform: scale(1.15) !important;
      }
      
    }
  }
}

@for $i from 1 through 8 {

  .skill:nth-child(#{$i}) {
      animation-delay: $i * 0.25s;
  }
}




@media screen and (min-width: 900px) {
  .hero-section {
    .container {
      .skills-heading{
        margin-top: 0;
      }
    }
  }
}
